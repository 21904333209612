<template>
  <b-card class="mb-4 pt-4" header-tag="header">
    <template #header>
      <b-row>
        <b-col cols="10">
          <h5 class="mb-0">{{ $route.name }}</h5>
        </b-col>
        <b-col cols="2" class="text-right">
          <b-button-group class="add-button-group">
            <b-btn variant="primary" href="/#/depature-group/create"
              >Tambah Data</b-btn
            >
          </b-button-group>
        </b-col>
      </b-row>
    </template>
    <b-row class="mb-3" style="margin-top: -20px;">
      <b-col cols="12">
        <b-form @submit.prevent="doFilter">
          <h6 class="mb-1" style="font-size: 1rem;">Filter Data</h6>
          <b-row class="filter-row">
            <b-col cols="4">
              <form-group-input
                ref="filter-name"
                type="text"
                placeholder=""
                :need-label="true"
                label="Nama kelompok keberankatan"
                v-model="filter.name"
                id="filter-name"
              />
            </b-col>
            <b-col cols="3">
              <label class="form-label">&nbsp;</label><br />
              <b-btn type="submit" variant="primary">Cari</b-btn>
              <b-btn
                type="button"
                @click.prevent="clearForm"
                variant="warning"
                class="ml-3 text-white"
                >Clear</b-btn
              >
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>
    <paginated-table
      v-if="showTable"
      ref="dataTable"
      aksi-dot
      :delete-reason="false"
      :can-delete="true"
      :can-edit="false"
      :data-url="apiUrl"
      :data-columns="columns"
      :dataParams="dataParams"
      :defaultDataParams="this.filter"
      with-show-drop
    >
      <template #addActionOption="props">
        <span
          @click="$router.push('depature-group/' + props.colData.id + '/edit')"
          >Tambah Jama'ah</span
        >
      </template>
      <template #expand="props">
        <div class="p-4">
          <div>
            <b-row>
              <b-col cols="2"><span>Nama Kelompok </span></b-col>
              <b-col cols="1">:</b-col>
              <b-col
                ><span>{{ props.colData.name }}</span></b-col
              >
            </b-row>
            <b-row>
              <b-col cols="2"><span>Estimasi Keberangkatan </span></b-col>
              <b-col cols="1">:</b-col>
              <b-col
                ><span>{{ props.colData.date }}</span></b-col
              >
            </b-row>
            <b-row>
              <b-col cols="2"><span>Tanggal Buat </span></b-col>
              <b-col cols="1">:</b-col>
              <b-col
                ><span>{{ props.colData.created_at }}</span></b-col
              >
            </b-row>
          </div>
          <div class="mt-5">
            <span class="h5">Data Jama'ah</span>
            <el-table
              border
              stripe
              style="width: 100%"
              :data="props.colData.detail"
              empty-text="Belum ada data jama'ah"
            >
              <el-table-column label="Jama'ah">
                <template #default="scope">
                  {{ scope.row.name }}
                </template>
              </el-table-column>
              <el-table-column
                fixed="right"
                width="70"
                align="center"
                label="Aksi"
              >
                <template #default="scope">
                  <font-awesome-icon
                    @click="deletePilgrim(scope.row.id)"
                    class="pointer"
                    style="color: #e63757"
                    icon="trash"
                  />
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </template>
    </paginated-table>
  </b-card>
</template>

<script>
// import { objectToOptions, getYears, formatModelValue } from '@/_helpers'
import paginatedTable from "@/components/PaginatedTable.vue";
import formGroupInput from "@/components/FormGroupInput.vue";
import DepatureGroup from "@/models/DepatureGroup.js";

const depatureGroupModel = new DepatureGroup();

export default {
  components: {
    formGroupInput,
    paginatedTable,
  },
  data() {
    return {
      showTable: true,
      isLoading: false,
      apiUrl: depatureGroupModel.endpoint,
      columns: [
        {
          prop: "name",
          label: "Nama Kelompok",
          sortable: true,
          resizable: true,
          minWidth: 200,
        },
        {
          prop: "date",
          label: "Tgl. Keberangkatan",
          sortable: true,
          resizable: true,
          minWidth: 100,
        },
      ],
      filter: {
        name: null,
      },
      dataParams: ["name"],
    };
  },
  beforeMount() {
    this.$router.push({ query: { ...this.$route.query } }).catch((err) => {});
  },
  mounted() {},
  methods: {
    deletePilgrim: function(id) {
      this.showTable = false
      depatureGroupModel
        .removePilgrim(id)
        .then((resp) => {
          this.$store.dispatch(
            "notification/success",
            "Jama'ah Berhasil dihapus."
          );
          this.showTable = true
        })
        .catch((err) => {
          this.$store.dispatch("notification/error", err);
        });
    },
    clearForm() {
      this.$nextTick(() => {
        this.filter = {
          name: null,
        };
        setTimeout(() => {
          this.doFilter();
        }, 100);
      });
    },
    doFilter() {
      this.$refs.dataTable.doFilter(this.filter);
    },
  },
};
</script>
