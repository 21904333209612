import axios from "axios";
import BaseModel from "@/models/BaseModel.js";

export default class DepatureGroup extends BaseModel {
  fields = {
    'name': {
      type: 'text',
      label: 'Kelompok Keberangkatan',
    },
    'date': {
      type: 'date',
      label: 'Tgl. Keberangkatan'
    }
  };

  endpoint = process.env.VUE_APP_API_URL + "depature-group";
  pilgrim = this.endpoint + "/pilgrim";

  store(data) {
    console.log("masuk");
    return new Promise((resolve, reject) => {
      axios
        .post(this.endpoint, data)
        .then((resp) => resolve(resp))
        .catch((err) => reject(err));
    });
  }

  list(params = {}) {
    return new Promise((resolve, reject) => {
      const url = new URL(this.endpoint);
      const urlParams = new URLSearchParams(url.search);
      Object.keys(params).forEach((key) => {
        if (
          typeof params[key] === "string" ||
          typeof params[key] === "number"
        ) {
          urlParams.append(key, params[key]);
        }
      });
      axios
        .get(this.endpoint + "?" + urlParams.toString())
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  addPilgrim(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(this.pilgrim, data)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  update(id, value) {
    let formData = new FormData();
    Object.keys(value).forEach((key) => {
      formData.append(key, value[key]);
    });
    formData.append("_method", "PATCH");
    return new Promise((resolve, reject) => {
      axios
        .post(this.endpoint + "/" + id, formData)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  removePilgrim(id) {
    return new Promise((resove, reject) => {
      axios
        .delete(this.pilgrim + "/" + id)
        .then((resp) => resove(resp))
        .catch((err) => reject(err));
    });
  } 

  exportExcel(id) {
    window.open(process.env.VUE_APP_API_BASE_URL + 'export/departure-group/' + id, '_blank')
  }
}
